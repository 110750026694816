import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qc-supervision',
  templateUrl: './qc-supervision.component.html',
  styleUrls: ['./qc-supervision.component.scss']
})
export class QcSupervisionComponent implements OnInit {
  initLoading = true;
  loadingMore = false;
  list: Array<{ content: any; title: String }> = [];

  optionList = [
    2020,
    2021
  ];
  selectedValue = '';
  constructor() { }

  ngOnInit() {
    this.getData();
  }

  getData(): void {
    this.list = [
      {
        "title":"麻咪咪哄",
        "content":"m反馈距离科学成就见佛菩萨发品为 u 人了解了大风具女性穿着女激发快乐打算减肥啦减肥"
      },
      {
        "title":"麻咪咪哄",
        "content":"m反馈距离科学成就见佛菩萨发品为 u 人了解了大风具女性穿着女激发快乐打算减肥啦减肥"
      }
    ];
    this.initLoading = false;
  }

  onLoadMore(): void {
    this.loadingMore = true;
    this.loadingMore = false;
  }

  handleChange(value): void {
    console.log(value);
  }

}
