import {Injectable} from '@angular/core';
import * as url from 'url';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {encryption} from '../utils/util';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  //请求头设置
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic Y2hpbmFjeXRvbG9neTpjaGluYWN5dG9sb2d5'
    })
  };
  //版本号
  sv: string;
  baseUrl: string;

  constructor(public http: HttpClient) {
    this.sv = '1.0.1';
    this.baseUrl = this.get_href('', {});
  }

  public set_header_auth(auth: string): Promise<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ auth
      })
    };
    return null;
  }

  //组装url
  public get_href(path: string, query: Object = null): string {
    let href = url.format({
      protocol: 'https',
      host: 'mdt.upathology.cn',
      pathname: path,
      query: Object.assign({}, query),
    });
    return href;
  }

  /**
   *
   * @description 请求的方法封装
   * @param path 路径
   * @param options 请求头设置
   * @param query get形式请求参数
   * @param data post形式请求参数
   * @param method 请求的方法
   */
  private async get_resp(
    path: string,
    options: Object,
    query: Object = {},
    data: Object = {},
    method: string = 'GET'
  ): Promise<any> {
    let result: Observable<any>;
    const href = this.get_href(path, query);
    switch (method.toUpperCase()) {
      case 'GET':
        result = this.http.get(href, options);
        break;
      case 'POST':
        result = this.http.post(href, options, data);
        break;
      case 'DELETE':
        result = this.http.delete(href, options);
        break;
      case 'PUT':
        result = this.http.put(href, options, data);
        break;
      default:
        throw 'type 类型错误！';
    }
    return new Promise((resolve, reject) => {
      result.subscribe(
        (res) => {
          if (res['code'] === 0 || res['access_token']) {
            resolve(res['data'] || res);
          } else {
            reject(res['msg']);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  // 登录
  login(data) {
    // 密码做加密
    data = encryption({
      data: data,
      key: 'chinacytology135',
      param: ['password']
    });
    const query = Object.assign({}, data, {'grant_type': 'password', 'scope': 'server'});
    return this.get_resp('ccn/auth/oauth/token', this.httpOptions, query, this.httpOptions, 'POST');
  }

  // 获取用户信息
  getUserInfo() {
    return this.get_resp('ccn/admin/user/info', this.httpOptions);
  }

}
