import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  validateForm!: FormGroup;
  passwordVisible;
  param: any = {
    username: 'pisAdmin',
    password: 'unic123456',
  };
  constructor(
    private router: Router,
    private message: NzMessageService,
    private userService: UserService,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.validateForm = this.fb.group({
      username: ['pisAdmin', [Validators.required]],
      password: ['unic123456', [Validators.required]],
      remember: [null],
    });
  }
  async submitForm() {
    try {
      this.userService.login(this.validateForm.value);
    } catch (err) {
      this.message.error(err);
    }
  }
}
