import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  news = [
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接kj分开多久啊失联飞机名称，v 你充满自信呢女女婿才能接口连接饭卡上的v 成绩，v 你真行，女接口连接"
    },
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接"
    },
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接"
    },
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接"
    }
  ]
  
  constructor() { }

  ngOnInit() {
  }

}
