<div class="experts_box">
  <div class="header_box">
    <div class="qc_title">专家简介</div>
  </div>
  <div class="content_box">
    <ng-container *ngFor="let item of expertsList">
      <nz-card nzHoverable style="width:240px" [nzCover]="coverTemplate">
        <nz-card-meta [nzTitle]="item.title" [nzDescription]="item.content"></nz-card-meta>
      </nz-card>
      <ng-template #coverTemplate>
        <img alt="example" [src]="item.url" />
      </ng-template>
    </ng-container>
  </div>
</div>
