<ul nz-menu [nzTheme]="nzTheme" nzMode="inline" [nzInlineCollapsed]="isCollapsed" style="padding-bottom: 24px;"
  class="sider">
  <ng-container *ngFor="let menu of menus">
    <li nz-submenu *ngIf="menu.children.length">
      <span title><i nz-icon [nzType]="menu.icon" nzTheme="fill"></i><span class="nav-text">{{menu.menuTitle}}</span></span>
      <ul>
        <li nz-menu-item *ngFor="let children of menu.children" [routerLink]="[children.path]" (click)="change(children)">
          {{children.menuTitle}}
        </li>
      </ul> 
    </li>
    <li nz-menu-item *ngIf="menu.children.length === 0" [routerLink]="[menu.path]" (click)="change(menu)">
      <span title><i nz-icon [nzType]="menu.icon" nzTheme="fill"></i><span class="nav-text">{{menu.menuTitle}}</span></span>
    </li>
  </ng-container>
</ul>
