import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  /**
   * 系统菜单
   */
  public user;

  constructor(private api: ApiService, private router: Router) {}

  /**
   * 登录
   */
  login(data) {
    this.api.login(data).then(res=>{
      //更新令牌
      this.api.set_header_auth(res.access_token);
      this.getUserInfo();
    })
  }
  /**
   * 退出登录
   */
  logout() {
    this.api.httpOptions.headers.set('Authorization','Basic Y2hpbmFjeXRvbG9neTpjaGluYWN5dG9sb2d5');
    this.router.navigate(["/login"]);
  }

  /**
   * 获取用户信息
   */
  getUserInfo() {
    this.api.getUserInfo().then(res=>{
      this.router.navigate(["/home"]);
    })
  }

}
