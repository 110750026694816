import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { QcCenterComponent } from './qc-center/qc-center.component';
import { QcSupervisionComponent } from './qc-supervision/qc-supervision.component';
import { QcNewsComponent } from './qc-news/qc-news.component';
import { QcSignupComponent } from './qc-signup/qc-signup.component';
import { AboutComponent } from './about/about.component';
import { FeedbackComponent } from './feedback/feedback.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "index",
        pathMatch: "full"
      },
      {
        path: 'index',
        component: HomeComponent,
        data:{
          name:"首页"
        }
      },
      {
        path: 'qcCenter',
        component: QcCenterComponent,
        data:{
          name:"质控中心"
        }
      },
      {
        path: 'qcSupervision',
        component: QcSupervisionComponent,
        data:{
          name:"质控督查"
        }
      },
      {
        path: 'qcNews',
        component: QcNewsComponent,
        data:{
          name:"质控资讯"
        }
      },
      {
        path: 'qcSiginup',
        component: QcSignupComponent,
        data:{
          name:"质控报名"
        }
      },
      {
        path: 'about',
        component: AboutComponent,
        data:{
          name:"关于我们"
        }
      },
      {
        path:'feedback',
        component: FeedbackComponent,
        data:{
          name:"反馈意见"
        }
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
