import { ValidatorFn, AbstractControl } from '@angular/forms';
import CryptoJS from 'crypto-js';

export function validateRex(type: string, validateRex: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    // 获取当前控件的内容
    const str = control.value;
    // 设置我们自定义的严重类型
    const res = {};
    res[type] = { str };
    // 如果验证通过则返回 null 否则返回一个对象（包含我们自定义的属性）
    return validateRex.test(str) ? null : res;
  };
}

export function timeCountdown(obj): Object {
  // const TIME_COUNT = obj.waitTime;
  if (!obj.timer) {
    obj.canGet = false;
    obj.timer = setInterval(() => {
      if (obj.waitTime > 0) {
        obj.waitTime--;
      } else {
        obj.canGet = true;
        clearInterval(obj.timer); //清空定时器
        obj.timer = null;
      }
    }, 1000);
  }
  return {
    timer: obj.timer,
    canGet: obj.canGet,
    waitTime: obj.waitTime,
  };
}

/**
 * 防抖函数
 * @param fn
 * @param delay
 */
export function debounce(fn: Function, duration: number) {
  let timer = null;
  let result = () => {
    clearTimeout(timer);
    timer = setTimeout(fn, duration);
  };
  return result;
}

/**
 * 加密
 * @param params
 */
export const encryption = (params) => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

/**
 * 浅拷贝，
 * 不可拷贝函数
 * 不可拷贝循环引用
 * @param target
 */
export const shadowClone = (target) => JSON.parse(JSON.stringify(target));
