<div class="search_box">
  <nz-select [(ngModel)]="selectedValue" (ngModelChange)="handleChange($event)" nzAllowClear nzPlaceHolder="全部">
    <nz-option *ngFor="let option of optionList" [nzValue]="option" [nzLabel]="option"></nz-option>
  </nz-select>
</div>
<nz-list class="loadmore_list_box" [nzLoading]="initLoading">
  <nz-list-item *ngFor="let item of list">
      <nz-list-item-meta
        nzAvatar="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
        nzDescription="大威天龙"
      >
        <nz-list-item-meta-title>
          {{ item.title }}
        </nz-list-item-meta-title>
      </nz-list-item-meta>
      {{item.content}}
      <ul nz-list-item-actions>
        <nz-list-item-action><a routerLink="/index">查看详情</a></nz-list-item-action>
      </ul>
  </nz-list-item>
  <div class="loadmore" nz-list-load-more>
    <button nz-button *ngIf="!loadingMore" (click)="onLoadMore()">更多</button>
  </div>
</nz-list>

