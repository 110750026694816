import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from './pages/register/register.component';
import { NoPageComponent } from './pages/no-page/no-page.component';

const routes: Routes = [
  { path: "", redirectTo: "/index", pathMatch: "full" },
  {
    path: "register",
    component: RegisterComponent,
    data: { label: "系统注册" },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { label: "系统登陆" },
  },
  {
    path: "index",
    loadChildren: () =>
      import("./pages/customer/customer.module").then(
        (mod) => mod.CustomerModule
      ),
    data: { label: '用户端'},
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/admin/admin.module").then(
        (mod) => mod.AdminModule
      ),
    data: { label: '管理端'},
  },
  {
    path: "**",
    component: NoPageComponent,
    data: { label: "404" },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
