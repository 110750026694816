<div class="content">
  <div class="top_header">
    <img
      width="60px"
      height="60px"
      src="../../../assets/imgs/login/logo.png"
      alt=""
    />
  </div>
  <div class="content_box">
    <div class="register_wrapper">
      <h2 class="title">注册账号</h2>
      <form
        nz-form
        [formGroup]="registerForm"
        nzLayout="vertical"
        class="reset-form"
      >
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired>登陆名</nz-form-label>
              <nz-form-control nzErrorTip="请输入用户名!">
                <input
                  nz-input
                  formControlName="username"
                  placeholder="登陆名"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired
                >用户昵称</nz-form-label
              >
              <nz-form-control nzErrorTip="请输入用户昵称!">
                <input nz-input formControlName="likename" placeholder="昵称" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired
                >控制类型</nz-form-label
              >
              <nz-form-control>
                <nz-select id="controlType" formControlName="controlType">
                  <nz-option nzValue="HE质控" nzLabel="HE质控"></nz-option>
                  <nz-option
                    nzValue="免疫组化质控"
                    nzLabel="免疫组化质控"
                  ></nz-option>
                  <nz-option
                    nzValue="分子病理质控"
                    nzLabel="分子病理质控"
                  ></nz-option>
                  <nz-option
                    nzValue="细胞学质控"
                    nzLabel="细胞学质控"
                  ></nz-option>
                  <nz-option
                    nzValue="数字病理质控"
                    nzLabel="数字病理质控"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired
                >工作单位</nz-form-label
              >
              <nz-form-control>
                <nz-select id="hospital" formControlName="hospital">
                  <nz-option
                    nzValue="东莞华康医院"
                    nzLabel="东莞华康医院"
                  ></nz-option>
                  <nz-option
                    nzValue="免疫组化质控"
                    nzLabel="免疫组化质控"
                  ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired>科室</nz-form-label>
              <nz-form-control nzErrorTip="请输入科室!">
                <input
                  nz-input
                  formControlName="department"
                  placeholder="科室"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired>职称</nz-form-label>
              <nz-form-control>
                <nz-select id="jobtitle" formControlName="jobtitle">
                  <nz-option nzValue="专家" nzLabel="专家"></nz-option>
                  <nz-option nzValue="主任" nzLabel="主任"></nz-option>
                  <nz-option nzValue="副主任" nzLabel="副主任"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired>密码</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="passwordoneErrorTpl">
                <nz-input-group [nzSuffix]="suffixPassword">
                  <input
                    nz-input
                    [type]="passwordStatue ? 'text' : 'password'"
                    formControlName="password"
                    input-ltrim
                    placeholder="密码"
                    (ngModelChange)="validateConfirmPassword()"
                  />
                </nz-input-group>
                <ng-template #suffixPassword>
                  <i
                    nz-icon
                    [nzType]="passwordStatue ? 'eye-invisible' : 'eye'"
                    (click)="passwordStatue = !passwordStatue"
                  ></i>
                </ng-template>
                <ng-template #passwordoneErrorTpl let-control>
                  <ng-container
                    *ngIf="
                      control.hasError('length') || control.hasError('rule')
                    "
                  >
                    请输入8至16位数字和字母，字母区分大小写！
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true">确认密码</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="passwordErrorTpl">
                <nz-input-group [nzSuffix]="suffixTemplate">
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    nz-input
                    formControlName="confirm"
                    input-ltrim
                    placeholder="确认密码"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <i
                    nz-icon
                    [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                    (click)="passwordVisible = !passwordVisible"
                  ></i>
                </ng-template>
                <ng-template #passwordErrorTpl let-control>
                  <ng-container *ngIf="control.hasError('required')">
                    请确认密码!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('confirm')">
                    两次密码输入不一致!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true">邮箱</nz-form-label>
              <nz-form-control nzErrorTip="邮箱格式不正确!">
                <input
                  nz-input
                  formControlName="email"
                  placeholder="邮箱"
                  type="email"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzNoColon="true" nzRequired
                >手机号码</nz-form-label
              >
              <nz-form-control nzErrorTip="请输入手机号码!">
                <input
                  nz-input
                  formControlName="phone"
                  placeholder="手机号码"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label nzRequired nzNoColon="true">验证码</nz-form-label>
              <nz-form-control nzErrorTip="请录入验证码!">
                <nz-input-group
                  nzSearch
                  nzSize="large"
                  [nzAddOnAfter]="suffixButton"
                >
                  <input
                    type="text"
                    formControlName="code"
                    nz-input
                    placeholder="验证码"
                  />
                </nz-input-group>
                <ng-template #suffixButton>
                  <button nz-button nzType="primary" nzSize="large" nzSearch>
                    获取验证码
                  </button>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <nz-form-item>
          <nz-form-control>
            <div class="btn_wrapper">
              <button
                style="width: 30%;"
                nz-button
                class="done_button"
                [nzType]="'primary'"
                [disabled]="!registerForm.valid"
                (click)="handlerRegiste()"
              >
                确定
              </button>
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <a [routerLink]="['/index']" style="text-align: center;">返回首页</a>
        </nz-form-item>
      </form>
    </div>
  </div>
</div>
