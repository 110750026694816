import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomerRoutingModule } from './customer-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { QcCenterComponent } from './qc-center/qc-center.component';
import { QcSupervisionComponent } from './qc-supervision/qc-supervision.component';
import { QcNewsComponent } from './qc-news/qc-news.component';
import { QcSignupComponent } from './qc-signup/qc-signup.component';
import { AboutComponent } from './about/about.component';
import { FeedbackComponent } from './feedback/feedback.component';

import { ComponentsModule } from '../components/components.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSelectModule } from 'ng-zorro-antd/select';

const antd_modules = [
  NzLayoutModule,
  NzMessageModule,
  NzMenuModule,
  NzAvatarModule,
  NzModalModule,
  NzDropDownModule,
  NzInputModule,
  NzButtonModule,
  NzIconModule,
  NzTableModule,
  NzCheckboxModule,
  NzPaginationModule,
  NzFormModule,
  NzModalModule,
  NzRadioModule,
  NzBreadCrumbModule,
  NzDividerModule,
  NzCardModule,
  NzListModule,
  NzSelectModule
];

@NgModule({
  imports: [
    CommonModule,
    CustomerRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    ...antd_modules
  ],
  declarations: [
    LayoutComponent,
    HomeComponent,
    QcCenterComponent,
    QcSupervisionComponent,
    QcNewsComponent,
    QcSignupComponent,
    AboutComponent,
    FeedbackComponent
  ],
  providers: [],
})
export class CustomerModule {}
