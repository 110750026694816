import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import {validateNickname,validatelength,validatePassword,isMobile,validateNicknameLength,homeAddressLength,realNameLength,length128,length60} from '../../utils/validate';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm!:FormGroup;
  passwordVisible = false;
  passwordStatue = false;

  constructor(
    private fb: FormBuilder,
    private router:Router,
    private msg:NzMessageService
  ) {
    this.registerForm=this.fb.group({
      username:[null,[Validators.required]],
      likename: [null, [Validators.required]],
      controlType:['HE质控',[Validators.required]],
      hospital:['东莞华康医院',[Validators.required]],
      department:['',[Validators.required]],
      jobtitle:['专家',[Validators.required]],
      password:[null,[Validators.required]],
      confirm:[null,[this.confirmValidator]],
      email:[null,[]],
      phone:[null,[Validators.required]],
      code:[null,[Validators.required]]
    });
  }

  ngOnInit() {
  }

  //获取验证码
  getCode(){
    console.log("=======--------=========");
  }

  //注册
  handlerRegiste(){

  }

  // 密码一致校验
  validateConfirmPassword(): void {
    setTimeout(() => this.registerForm.controls.confirm.updateValueAndValidity());
  }
  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value)
    {
      return { error: true, required: true };
    }
    else if (control.value !== this.registerForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

}
