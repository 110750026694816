import { Component, OnInit, Inject } from "@angular/core";
import { DA_SERVICE_TOKEN, TokenService } from "@delon/auth";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"]
})
export class LogoComponent implements OnInit {
  applyName = "病理质控后台";
  constructor(@Inject(DA_SERVICE_TOKEN) private tokenService: TokenService) {}

  ngOnInit() {
    // this.applyName = this.tokenService.get().res.applyName;
  }
}
