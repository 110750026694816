<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <app-logo></app-logo>
    </div>
    <app-sider [isCollapsed]="isCollapsed" [nzTheme]="nzTheme" (activeLink)="active($event)"></app-sider>
  </nz-sider>
  <nz-layout>
    <nz-header [ngStyle]="{'width': wh }">
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed" (click)="change()">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </span>
      </div>
      <div class="header-right">
        <!-- <app-logout [out]="out"></app-logout> -->
      </div>
    </nz-header>
    <nz-content [ngStyle]="{'width': win }">
      <div class="inner-content">
        <app-tabset [tabs]="tabs" [width]="isCollapsed?'0':'0'" (closeTab)="closeTab($event)" [num]="num">
        </app-tabset>
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

