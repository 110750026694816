import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { ExpertsViewComponent } from './experts-view/experts-view.component';

let commonArr = [
  ExpertsViewComponent,
]

import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCardModule } from 'ng-zorro-antd/card';

let ngModuleArr = [
    NzBadgeModule,
    NzDropDownModule,
    NzIconModule,
    NzSelectModule,
    NzUploadModule,
    NzInputModule,
    NzButtonModule,
    NzRadioModule,
    NzMessageModule,
    NzGridModule,
    NzDrawerModule,
    NzCheckboxModule,
    NzCardModule
]

@NgModule({
    declarations: [
        ...commonArr
    ],
    imports: [
        HttpClientModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        ...ngModuleArr,
    ],
    providers: [],
    exports: [
        ...commonArr
    ],
})
export class ComponentsModule { }
