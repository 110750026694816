import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';

import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { DelonMockModule } from "@delon/mock";

const antd_modules = [
  NzLayoutModule,
  NzMessageModule,
  NzMenuModule,
  NzAvatarModule,
  NzModalModule,
  NzDropDownModule,
  NzInputModule,
  NzButtonModule,
  NzIconModule,
  NzTableModule,
  NzCheckboxModule,
  NzPaginationModule,
  NzFormModule,
  NzModalModule,
  NzRadioModule,
  NzBreadCrumbModule,
  NzDividerModule,
  NzCardModule,
  NzListModule,
  NzSelectModule
];

@NgModule({
  imports: [
    LayoutModule,
    SharedModule,
    CommonModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    DelonMockModule.forChild(),
    ...antd_modules
  ],
  declarations: [

  ],
  providers: [],
})
export class AdminModule {}
