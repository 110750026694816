<div class="layout_box">
  <header>
    <div class="top_box">
      <div class="login_warpper">
        <div class="admin_wapper" *ngIf="isLogin">
          <i nz-icon nzType="setting" nzTheme="fill"></i>
          <span>后台管理</span>
        </div>
        <div class="phone_wapper">
          <i nz-icon nzType="phone" nzTheme="fill"></i>
          <span>888-80808888</span>
        </div>
        <div>
          <a routerLink="/login">登陆</a>
          <span style="padding: 0 8px;">/</span>
          <a routerLink="/register">注册</a>
        </div>
        <div class="login_out_wrapper" *ngIf="isLogin">
          <a>退出</a>
        </div>
      </div>
    </div>
    <div class="nav_box">
      <nav>
        <div class="logo_box">
          <img
            src="../../../../assets/imgs/login/logo.png"
            width="76px"
            height="76px"
            alt=""
          />
          <h2>广东省临床病理质量控制中心</h2>
        </div>
        <div class="navigater_wrapper">
          <ul nz-menu nzMode="horizontal">
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/index">首页</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/qcCenter">质控中心</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/qcSupervision">质控督查</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/qcNews">质控资讯</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a routerLink="/qcSiginup">质控报名</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
  <div class="content">
    <div class="view_wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <div class="relation_des">
      <div class="relation_box">
        <div class="left_box">
          <div>
            <a routerLink="/about">关于我们</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a routerLink="/feedback">反馈意见</a>
          </div>
          <div class="descript">
            联系我们
          </div>
          <div class="descript">
            服务热线： 888-80808888
          </div>
          <div class="descript">
            技术邮箱：5188@163.com
          </div>
          <div class="descript">
            联系地址：北京市海淀区上地五街七号
          </div>
        </div>
        <div class="right_box">
          <div class="title">友情链接</div>
          <div class="link_box">
            <div class="link_content">
              <a target="_blank" href="http://www.sysu.edu.cn/cn/index.htm">中山大学</a>
              <a target="_blank" href="https://www.zssy.com.cn/">中山大学附属第三医院</a>
              <a target="_blank" href="http://www.zs6y.com/page/web/pc/index.htm">中山大学附属第六医院（肠胃肛门医院）</a>
              <a target="_blank" href="http://www.gzzoc.com/">中山大学附属眼科医院（中山眼科中心）</a>
              <a target="_blank" href="https://www.sysucc.org.cn/">中山大学附属肿瘤医院（肿瘤防治中心）</a>
            </div>
            <div class="link_content">
              <a target="_blank" href="http://www.syshospital.com/">中山大学附属第二医院（孙逸仙纪念医院）</a>
              <a target="_blank" href="http://www.zsufivehos.com/">中山大学附属第五医院（珠海医院）</a>
              <a target="_blank" href="http://www.sysush.com/">中山大学附属第七医院</a>
              <a target="_blank" href="https://www.zdkqyy.com/">中山大学附属口腔医院（光华口腔医院）</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="website_des">Copyright © 2015-2020 京ICP备14007869号-6</div>
  </footer>
</div>
