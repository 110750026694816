import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experts-view',
  templateUrl: './experts-view.component.html',
  styleUrls: ['./experts-view.component.scss']
})
export class ExpertsViewComponent implements OnInit {
  expertsList = [
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接",
      "url":"https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    },
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接",
      "url":"https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    },
    {
      "title":"灭火器的使用技巧",
      "content":"收起激发科技大厦看风景u 诶我睿哦谯污染激发快乐睡觉啊分类积极快乐接口连接",
      "url":"https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
