<div class="home_wrapper">
  <div class="img_top_box">
    <img src="../../../../assets/imgs/home/home.png" width="100%" alt="" />
  </div>
  <div class="qc_news_center">
    <div class="qc_box">
      <div class="header_box">
        <div class="qc_title">质控资讯</div>
        <a routerLink="/qcNews">更多 》</a>
      </div>
      <section>
        <ng-container *ngFor="let item of news">
          <nz-card style="margin-bottom: 10px;" [nzBodyStyle]="{'height':'58px','overflow':'hidden'}" [nzTitle]="item.title" nzSize="small" [nzExtra]="extraNew">
            {{item.content}}
          </nz-card>
          <ng-template #extraNew>
            <a>查看</a>
          </ng-template>
        </ng-container>
      </section>
    </div>
    <div class="space_item"></div>
    <div class="qc_box">
      <div class="header_box">
        <div class="qc_title">质控中心</div>
      </div>
      <section>
        <img
          style="margin-right: 10px; width: 55%;float: left;"
          src="../../../../assets/imgs/home/qcCenter.png"
        />
        医院病理科现有诊断医师 11 名，技术员 11 名，资料员 1 名。其中博士 8
        名，硕士 6
        名，从主任医师到副主任医（技）师、主治医师、医（技）师，形成了良好的梯队。特聘南方医科大学肿瘤研究所所长、中科院院士姚开泰教授为名誉顾问；加拿大
        Calgary
        大学医学院病理科高祖华博宁为客座教授。多年来，科室以其严格的科学管理、高质量的内涵服务和离水平的诊断技术赢得了病患者和临床医生的信任，在省内外病理界以及医院中享有较好的声誉．现为山东省病理质量控制中心，山东省医师协会病理科医师分会主委单位．山东省抗癌协会肿瘤病理分会主委单位．山东大学“
        985 '
        ，重点工程建设单位，山东省临床医学重点专科，山东省医药卫生重点实验室，山东省法医司法鉴定中心。
        2011
        年荣获山东省卫生系统质量品牌。除日常工作外，在省内率先开展了免疫组化、免疫荧光、基因测序以及定量户
        CR
        技术进行肿瘤药物敏感性和突变基因检测，从分子和蛋白质水平指导临床个体化治疗．对改善免疫性疾病和恶性肿瘤治疗的有效性具有重要的实际意义；将原位杂交技术应用于临床日
        ER 一 2
        检测、日户岁病奏等的检测，除可提供准确可靠的诊断和治疗依据外，还增强了对疾病的发生发展机制的认知；
        TC
        下膜氏液基超薄细胞学检测，显著改善了涂片质量，提高了宫颈癌前病变和尿液、胸腹水、痰中瘤细胞的检出率；骨髓活检诊断技术、肾活检诊断技术、移植病理诊断技术，为省内特色检查项目。另外创建了我省第一家新鲜标本组织库和分子遗传病理实验室，开辟了医学科研和教学的新途径；安装启用了实时互动和数字切片远程会诊系统，为全面开展国内外的学术交流，提高全省的病理诊断水平打下了良好基础。
      </section>
    </div>
  </div>
  <app-experts-view></app-experts-view>
  <div class="sign_up_box">
    <div class="text_box">
      <p>学习创新病理技术</p>
      <p class="en_text">Learning and Innovating Pathological Technology</p>
      <a routerLink="/qcSiginup">
        <button nz-button nzType="primary" nzSize="large" nzShape="round">
          质控报名<i nz-icon nzType="arrow-right" nzTheme="outline"></i>
        </button>
      </a>
    </div>
  </div>
</div>
