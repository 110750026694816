<div class="login-container">

  <div class="login-header">
    <img src="../../../assets/imgs/login/logo.png" alt="" width="62px" height="62px">
    <span>广东省临床病理质量控制中心</span>
  </div>

  <div class="content">
    <div class="img">
      <img src="../../../assets/imgs/login/left-img.png" alt="" width="458px" height="428px">
    </div>
    <div class="login-info-box">
      <div class="title-box">登&nbsp;&nbsp;录</div>
      <div class="divide-bar"></div>
      <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="email">用户名</nz-form-label>
          <nz-form-control nzErrorTip="请输入你的用户名!">
            <nz-input-group [nzSuffix]="prefixTemplateUser">
              <input type="text" nz-input formControlName="username" placeholder="请输入你的用户名!" />
            </nz-input-group>
            <ng-template #prefixTemplateUser><i nz-icon nzType="user" nzTheme="outline"></i></ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="email">密码</nz-form-label>
          <nz-form-control nzErrorTip="请输入你的密码!">
            <nz-input-group [nzSuffix]="suffixTemplatePwd">
              <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                placeholder="请输入你的密码!" />
            </nz-input-group>
            <ng-template #suffixTemplatePwd>
              <i nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                (click)="passwordVisible = !passwordVisible"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div class="login-button">
          <nz-form-item>
            <label nz-checkbox formControlName="remember">
              <span>记住密码</span>
            </label>
          </nz-form-item>
          <button nz-button [nzType]="'primary'">登&nbsp;&nbsp;录</button>
        </div>
        <div class="forget-register">
          <!-- <a routerLink="/register">忘记密码？</a> -->
          <a routerLink="/register">去注册</a>
        </div>
      </form>
    </div>
  </div>
</div>
