import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginGuardService } from './shared/guard/login-guard.service';
import { LayoutDefaultComponent } from './layout/layout-default/layout-default.component';
const canActivate = [LoginGuardService];
const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    // canActivate,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () =>
          import('./routes/home/home.module').then((mod) => mod.HomeModule),
        data: {
          breadcrumb: '首页',
        },
      },
      {
        path: 'userRight',
        loadChildren: () =>
          import('./routes/auth/auth.module').then((mod) => mod.AuthModule),
        data: {
          breadcrumb: '用户管理',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
