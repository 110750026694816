<nz-breadcrumb style="margin-top: 15px;">
  <nz-breadcrumb-item>
    <a [routerLink]="['/index']">首页</a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>
    关于我们
  </nz-breadcrumb-item>
</nz-breadcrumb>
<div class="about_wrapper">
  <img width="100%" src="../../../../assets/imgs/about/about.png" alt="">
  <p>网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介</p>
  <p>网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介网站简介</p>
</div>
