<div class="container">
  <div class="passport">
    <div class="head">
      <img src="../../../assets/img/logo.png" alt="logo" class="logo">
      <span class="title">SUN PAWD</span>
      <div class="desc">后台管理系统前端框架(Angular)</div>
    </div>
    <div class="wrap">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
