<div class="wrapper">
  <div class="center_box">
    <div class="item" style="background-color: #007bff;">
      <p>HE质控</p>
      <span>点击报名</span>
    </div>
    <div class="item" style="background-color: #28a745;">
      <p>免疫组质控</p>
      <span>点击报名</span>
    </div>
    <div class="item" style="background-color: #17a2b8;">
      <p>分子病理质控</p>
      <span>点击报名</span>
    </div>
    <div class="item" style="background-color: #ffc107;">
      <p>细胞学质控</p>
      <span>点击报名</span>
    </div>
    <div class="item" style="background-color: #395882;">
      <p>数字病理质控</p>
      <span>点击报名</span>
    </div>
  </div>
</div>
