<nz-breadcrumb style="margin-top: 15px;">
  <nz-breadcrumb-item>
    <a [routerLink]="['/index']">首页</a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item>
    反馈意见
  </nz-breadcrumb-item>
</nz-breadcrumb>
<div class="feedback_wrapper">
  <div class="option_box">欢迎提出宝贵的意见</div>
  <form nz-form [formGroup]="validateForm" nzLayout="vertical" (ngSubmit)="submitForm()">
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzNoColon="true" nzRequired>姓名</nz-form-label>
          <nz-form-control nzErrorTip="请输入用户名!">
            <input nz-input formControlName="userName" placeholder="姓名" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzNoColon="true" nzRequired>电话</nz-form-label>
          <nz-form-control nzErrorTip="请输入电话号码!">
            <input nz-input formControlName="phone" placeholder="电话" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="24">
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzNoColon="true" nzRequired>邮箱</nz-form-label>
          <nz-form-control nzErrorTip="请输入邮箱!">
            <input nz-input formControlName="email" placeholder="邮箱" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="12">
        <nz-form-item>
          <nz-form-label nzNoColon="true">单位</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="unit" placeholder="单位" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <nz-form-item>
      <nz-form-label nzNoColon="true" nzRequired>反馈意见/建议</nz-form-label>
      <nz-form-control nzErrorTip="请输入意见！">
        <textarea formControlName="comment" nz-input rows="4" placeholder="请输入您的宝贵意见！"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary">提交</button>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
